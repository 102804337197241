import React, {useContext} from 'react'
import './style.scss'
import NaviContext from '../../../context/NaviContext'

function Logo(props) {
	const naviContext = useContext(NaviContext)

  return (
		<div id="master-logo" className='logo'>
			SytySmyk.pl
		</div>
  )
}

export default Logo